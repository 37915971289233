<template>
  <section id="batchCommand">
    <propertiesPageHeader
      :name="property.name"
      :address="property.address"
      :units="property.units"
      :applianceCounts="property.applianceCounts"
      :managerEmail="property.managerEmail"
      :managerName="property.managerName"
    ></propertiesPageHeader>
    <div class="listHeader max-main">
      <div class="relative w-full pb-10">
        <h1 class="text-2xl font-extrabold text-yellow">BATCH COMMAND</h1>
        <div class="absolute top-0 right-0">
          <button class="px-16">APPLY</button>
          <router-link
            to="/properties/show"
            class="relative -top-1 inline-block ml-5 text-ltgrey text-md font-bold"
            >CLOSE&nbsp;&nbsp;X</router-link
          >
        </div>
      </div>
    </div>
    <div class="batch-columns relative w-full rounded p-4 grey grid grid-cols-12 gap-5 text-white">
      <div class="xl:col-span-3 col-span-6 h-full">
        <h2 class="font-extrabold flex-0">
          Select Rooms<span class="inline-block float-right"
            ><img src="@/assets/img/batchDropDown.svg" alt=""
          /></span>
        </h2>
        <div
          class="rooms w-full rounded dkgrey mt-5 mx-auto content-center p-4 flex flex-1 flex-col"
        >
          <div class="overflow-y-auto">
            <ul>
              <li class="list-open">
                Floor 1
                <ul>
                  <li class="selected">101</li>
                  <li class="selected">102</li>
                  <li class="selected">103</li>
                  <li class="selected">104</li>
                  <li>105</li>
                  <li class="selected">106</li>
                  <li>107</li>
                  <li>108</li>
                </ul>
              </li>
              <li>Floor 2</li>
              <li>Floor 3</li>
              <li>Floor 4</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="xl:col-span-3 col-span-6 h-full">
        <h2 class="font-extrabold flex-0">Select Appliance</h2>
        <div class="appliances w-full rounded dkgrey flex-1 mt-5 mx-0 p-4">
          <div class="overflow-y-auto">
            <article v-for="(item, idx) in applianceList" :key="idx" class="py-2">
              <a
                @click="(e) => toggleSelect(e, item.label)"
                :class="{ selected: item.selected }"
                class="cursor-pointer flex content-center items-center rounded p-2"
              >
                <p class="flex-0 m-auto pl-2 pr-4"><img :src="item.img" :alt="item.label" /></p>
                <span class="flex flex-1 content-center m-auto" v-html="item.label"></span>
              </a>
            </article>
            <!-- <img class="column2 w-full block m-auto" src="@/assets/img/batchColumn2.svg" alt="" /> -->
          </div>
        </div>
      </div>
      <div class="xl:col-span-6 col-span-12">
        <h2 class="font-extrabold flex-0">Set Mode</h2>
        <div
          v-if="selectedLabel === zonelineLabels.name || selectedLabel === 'Duct Free Split'"
          class="w-full rounded dkgrey mt-5 mx-0 content-center p-4"
        >
          <div class="w-full flex justify-between underline pb-4">
            <img
              v-if="selectedLabel === zonelineLabels.name && zonelineLabels.name === 'Zoneline'"
              src="@/assets/img/batch_column3/row1_col1.svg"
              alt=""
            />
            <img
              v-if="selectedLabel === zonelineLabels.name && zonelineLabels.name === 'VTAC'"
              src="@/assets/img/batch_column3/row1_col1_vtac.svg"
              alt=""
            />
            <img
              v-if="selectedLabel === 'Duct Free Split'"
              src="@/assets/img/batch_column3/row1_col1_dfs.svg"
              alt=""
            />
          </div>
          <div class="w-full flex justify-between underline py-4">
            <img src="@/assets/img/batch_column3/row2_col1.svg" alt="" />
            <img
              v-if="selectedLabel === zonelineLabels.name"
              src="@/assets/img/batch_column3/row2_col2.svg"
              alt=""
            />
            <img
              v-if="selectedLabel === 'Duct Free Split'"
              src="@/assets/img/batch_column3/row2_col2_dfs.svg"
              alt=""
            />
          </div>
          <h3 class="w-full pt-4">Aux Settings</h3>
          <div class="underline pb-4"></div>
          <div class="w-full flex justify-between pt-4">
            <img src="@/assets/img/batch_column3/row3_col1.svg" alt="" />
            <img src="@/assets/img/batch_column3/row3_col2.svg" alt="" />
          </div>
          <!-- <img class="column3 w-full block m-auto" src="@/assets/img/batchColumn3.svg" alt="" /> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import propertiesPageHeader from '@/components/propertiesPageHeader.vue';
  import { getDataByHostname } from '../utils/dataUtil';
  // import buildinglisting from '@/components/propertiesBuildingListItem.vue';

  export default {
    components: { propertiesPageHeader },
    data: function() {
      return {
        property: [{ name: '', applianceCounts: {}, buildings: [] }],
        zonelineLabels: this.$store.getters.applianceLabels('zoneline'),
        applianceList: [
          {
            label: 'Dehumidifier',
            img: `/images/batch_icons/dehumidifier.svg`,
            selected: false,
          },
          {
            label: 'Water Heater',
            img: `/images/batch_icons/WaterHeater.svg`,
            selected: false,
          },
          {
            label: this.$store.state.applianceLabels.zoneline.name,
            img: `/images/batch_icons/Zoneline.svg`,
            selected: true,
          },
          {
            label: 'Microwave',
            img: `/images/batch_icons/Microwave.svg`,
            selected: false,
          },
          { label: 'Cooktop', img: `/images/batch_icons/Cooktop.svg`, selected: false },
          {
            label: 'Refrigerator',
            img: `/images/batch_icons/Refrigerator.svg`,
            selected: false,
          },
          {
            label: 'Vent Hood',
            img: `/images/batch_icons/VentHood.svg`,
            selected: false,
          },
          { label: 'Oven', img: `/images/batch_icons/Oven.svg`, selected: false },
          { label: 'Washer', img: `/images/batch_icons/Washer.svg`, selected: false },
          { label: 'Dryer', img: `/images/batch_icons/Dryer.svg`, selected: false },
          {
            label: 'Duct Free Split',
            img: `/images/batch_icons/DFS.svg`,
            selected: false,
          },
        ],
      };
    },
    mounted() {
      const dataByHostName = getDataByHostname();
      this.property = dataByHostName?.properties?.[0] || {};
    },
    watch: {},
    methods: {
      toggleSelect: function(el, label) {
        const zonelineLabels = this.zonelineLabels;
        this.applianceList.forEach((item) => {
          item.selected = false;
          if (item.label === label && [zonelineLabels.name, 'Duct Free Split'].includes(label)) {
            item.selected = !item.selected;
          }
        });
      },
    },
    computed: {
      selectedLabel: function() {
        const selected =
          this.applianceList &&
          this.applianceList.find((item) => {
            return item.selected;
          });
        return selected ? selected.label : '';
      },
    },
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';
  .underline {
    border-bottom: 2px solid $grey;
  }
  #batchCommand *::-webkit-scrollbar {
    width: 6px;
    transform: translateX(0px);
    position: relative;
    right: 5px;
  }
  #batchCommand * {
    scrollbar-width: thin;
    scrollbar-color: #5c5c5c;
  }
  #batchCommand *::-webkit-scrollbar-track {
    background: transparent;
  }
  #batchCommand *::-webkit-scrollbar-thumb {
    background-color: #5c5c5c;
    border-radius: 10px;
  }
  .batch-columns {
    // height: 754px;

    .rooms,
    .appliances {
      article,
      li {
        @extend .underline;
        p {
          width: 60px;
        }
      }
    }
  }
  .rooms {
    .overflow-y-auto {
      height: 473px;
    }

    ul {
      position: relative;
      li {
        position: relative;
        font-weight: 800;
        padding: 0.5rem 1rem 0.75rem;

        &:last-child {
          border-bottom: none;
          padding-bottom: 0.5rem;
        }
        &:before {
          content: ' ';
          position: relative;
          top: 6px;
          display: inline-block;
          width: 26px;
          height: 22px;
          margin-right: 0.5rem;
          background: transparent url('/images/batchSelectBox.svg') center center no-repeat;
        }
        &:after {
          content: ' ';
          position: absolute;
          top: 19px;
          right: 18px;
          display: inline-block;
          width: 16px;
          height: 9px;
          background: transparent url('/images/batchArrow.svg') center center no-repeat;
        }
        &.list-open {
          &:after {
            background: transparent url('/images/batchArrowOpen.svg') center center no-repeat;
          }
        }
        &.selected {
          &:before {
            background: transparent url('/images/batchSelectBoxSelect.svg') center center no-repeat;
          }
        }
        // position:relative;
        ul {
          margin-top: 0.75rem;
          border-top: 2px solid $grey;
          // position:absolute;
          // top:19px;
          li {
            padding-left: 1rem;

            &:after {
              content: none;
            }
          }
        }
      }
    }
  }
  .appliances {
    .overflow-y-auto {
      height: 473px;
    }
    article {
      a {
        color: $ltgrey;
        border: $dkgrey solid 1px;
        // svg {
        //   path {
        //     fill: $ltgrey;
        //   }
        // }
        // @media (hover: hover) {
        //   &:hover {
        //     color: #fff;
        //     border: $yellow solid 1px;
        //     img {
        //       filter: brightness(200%);
        //     }
        //   }
        // }
        &.selected {
          color: #fff;
          border: $yellow solid 1px;
          img {
            filter: brightness(200%);
          }
        }
      }
    }
  }
  .column1 {
    width: 225px;
  }
  .column2 {
    width: 202px;
  }
  .column3 {
    width: 331px;
  }
</style>
